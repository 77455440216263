<template>
  <multi-select
    class="company-select"
    :value="selectedCompany"
    track-by="id"
    label="name"
    :placeholder="placeholder"
    :options="companies || defaultCompanies"
    :searchable="true"
    :allow-empty="false"
    :loading="loading"
    deselect-label=""
    select-label=""
    @input="companySelected"
    @search-change="search({ name: $event })"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      {{ option.name }}
    </template>
  </multi-select>
</template>

<script>
  import MultiSelect from 'vue-multiselect'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'CompanySelect',
    components: {
      MultiSelect,
    },
    props: {
      companies: {
        type: Array,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Select a company',
      },
      resetCompany: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedCompany: null,
        loading: true,
      }
    },
    computed: {
      ...mapGetters('companies', {
        defaultCompanies: 'companies',
        companiesLoaded: 'companiesLoaded',
      }),
    },
    watch: {
      resetCompany: function () {
        this.companySelected(null)
        this.$emit('resetCompany', false)
      },
    },
    async mounted() {
      await this.$store.dispatch('companies/loadMore', false)
      this.loading = false

      if (this.value) {
        await this.$store.dispatch('companies/loadIfNotAvailableById', { id: this.value })

        this.selectedCompany = this.$store.getters['companies/find'](this.value)
      }
    },
    methods: {
      ...mapActions('companies', [
        'search',
      ]),

      companySelected(company) {
        this.selectedCompany = company
        this.$emit('input', company?.id)
      },
    },
  }
</script>
<style lang="scss">
  .multiselect {
    margin-top: 1.5em !important;
  }
  .multiselect__tags {
    border: 1px solid black; //TODO: This text should be teal after design team stuff
  }
</style>
